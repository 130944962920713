<template>
  <!-- eslint-disable -->
  <b-card>
    <div>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent="onSubmit(contractData)">
          <b-row>
            <!-- Field: Nombre -->
            <b-col cols="12" md="3">
              <b-form-group label="Nombre" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="contractData.name"
                >
                  <b-form-input
                    id="name"
                    placeholder="Nombre"
                    v-model="contractData.name"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo nombre es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: business_name -->
            <b-col cols="12" md="3">
              <b-form-group label="Empresa" label-for="business_name">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="contractData.business_name"
                >
                  <v-select
                    v-model="contractData.company_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="companyOptions"
                    :reduce="(val) => val.value"
                    :clearable="true"
                    input-id="business_name"
                    @input="changeBusiness"
                    placeholder="Ningun empresa seleccionada"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: responsible_sst -->
            <b-col cols="12" md="3">
              <b-form-group label="Responsable" label-for="responsible">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="contractData.responsible_sst"
                >
                  <v-select
                    v-model="contractData.responsible_sst"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="usersFilterOptions"
                    :reduce="(val) => val.label"
                    :clearable="true"
                    input-id="label"
                    placeholder="Ningun usuario seleccionada"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: development_by -->
            <b-col cols="12" md="3">
              <b-form-group label="Desarrollado por" label-for="development">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="contractData.development_by"
                >
                  <b-form-input
                    id="development"
                    v-model="contractData.development_by"
                    type="text"
                    placeholder="Desarrollado por"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Field: expiration_date -->
            <b-col cols="12" md="3">
              <b-form-group
                label="Fecha de expiración"
                label-for="expiration_date"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="contractData.expiration_date"
                >
                  <flat-pickr
                    v-model="contractData.expiration_date"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                    class="form-control"
                    placeholder="Selecciona una fecha"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: evaluation_date -->
            <b-col cols="12" md="3">
              <b-form-group
                label="Fecha de evaluación"
                label-for="evaluation_date"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="contractData.evaluation_date"
                >
                  <flat-pickr
                    v-model="contractData.evaluation_date"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                    class="form-control"
                    placeholder="Selecciona una fecha"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: scope -->
            <b-col cols="12" md="3">
              <b-form-group label="Alcance" label-for="scope">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="contractData.scope"
                >
                  <b-form-input
                    id="scope"
                    v-model="contractData.scope"
                    type="text"
                    placeholder="Alcance"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: qty_workers -->
            <b-col cols="12" md="3">
              <b-form-group label="Nro Trabajadores" label-for="qty_workers">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="contractData.qty_workers"
                >
                  <b-form-input
                    id="qty_workers"
                    v-model.number="contractData.qty_workers"
                    type="number"
                    placeholder="Nro Trabajadores"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Field: USERS -->
            <b-col cols="12" md="4">
              <b-form-group label="Usuarios" label-for="contract_user">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="contractData.contract_user"
                >
                  <v-select
                    v-model="contractData.contract_user"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="usersOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    multiple
                    input-id="contract_user"
                    placeholder="Ningun usuario seleccionado"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo nombre es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: TEMPLATES -->
            <b-col cols="12" md="4">
              <b-form-group label="Plantillas" label-for="template_id">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="contractData.template_id"
                >
                  <v-select
                    v-model="contractData.template_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="templateOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="template_id"
                    placeholder="Ninguna plantilla seleccionado"
                    @input="changeTemplate"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo nombre es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4" v-if="contractData.template_id != null">
              <b-form-group label="Ver plantilla" label-for="show_template">
                <b-button
                  variant="danger"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  id="show_template"
                  @click="showTemplate(contractData.template_id)"
                >
                  Ver detalles
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            type="submit"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :disabled="isDisabled"
          >
            <b-spinner v-if="isDisabled" small />
            <span v-if="isDisabled" class="px-1">guardando...</span>
            <span v-else>Guardar cambios</span>
          </b-button>
        </b-form>
      </validation-observer>
    </div>

    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      title="Detalles de Plantilla"
      centered
    >
      <b-card no-body>
        <b-table-simple hover responsive class="rounded-bottom mb-0">
          <b-thead head-variant="light">
            <b-tr>
              <b-th colspan="1"> Pregunta </b-th>
              <b-th colspan="4"> Sub-Pregunta </b-th>
              <b-th colspan="1"> Ref. Legal </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(item, index) in templateFormat">
              <b-th :rowspan="item.qtyChildren" v-if="item.title"
                >{{ item.numberParent }}. {{ item.title }}
              </b-th>
              <b-th colspan="4" class="text-left"
                >{{ item.number }}. {{ item.subtitle }}
              </b-th>
              <b-td colspan="1"> {{ item.ref_legal }} </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card>
    </b-modal>
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormDatepicker,
  BModal,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BSpinner,
} from "bootstrap-vue";
import CompanyService from "@/services/CompanyService";
import UserService from "@/services/UserService";
import TemplateService from "@/services/TemplateService";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import ContractService from "@/services/ContractService";
export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCardCode,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    flatPickr,
    BSpinner,
  },
  data() {
    return {
      contractData: {
        name: "",
        company_id: null,
        scope: "",
        qty_workers: null,
        evaluation_date: null,
        expiration_date: null,
        responsible_sst: "",
        development_by: "",
        template_id: null,
        contract_user: [],
        contract_detail: [],
      },
      currentPage: 1,
      showEntrie: 100,
      sort: "id",
      order: "asc",
      isDisabled: false,
      companyOptions: [],
      usersOptions: [],
      templateOptions: [],
      templateFormat: [],
      usersFilterOptions: [],
    };
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.getData();
  },
  methods: {
    async changeTemplate() {
      const resp = await TemplateService.getTemplate(
        this.contractData.template_id,
        this.$store
      );
      if (resp.success) {
        this.contractData.contract_detail = resp.data.template_detail;
        console.log(
          "this.contractData.contract_detail",
          this.contractData.contract_detail
        );
      }
    },
    async changeBusiness() {
      //USUARIOS
      this.contractData.contract_user = [];
      this.usersOptions = [];
      const url1 = `${this.contractData.company_id}/CONTRATISTA`;
      const resp1 = await UserService.getUsersByCompany(url1, this.$store);
      console.log("resp1", resp1);
      if (resp1) {
        resp1.data.forEach((element) => {
          this.usersOptions.push({
            label: element.name + " " + element.last_name,
            value: {
              user_id: element.id,
            },
          });
        });
      }
    },
    async getData() {
      //USERS GENERAL
      let arrayFilters = [
        {
          key: "equals",
          keyContains: "role",
          value: "jefe_area",
        },
      ];

      const urlUser =
        `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}&filter=` +
        JSON.stringify(arrayFilters);
      const respUser = await UserService.getUsers(urlUser, this.$store);
      if (respUser) {
        respUser.rows.forEach((element) => {
          this.usersFilterOptions.push({
            label: element.name + " " + element.last_name,
            value: {
              user_id: element.id,
            },
          });
        });
      }
      //EMPRESAS
      const url = `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}`;
      const resp = await CompanyService.getCompanys(url, this.$store);
      if (resp) {
        resp.rows.forEach((element) => {
          this.companyOptions.push({
            label: element.business_name,
            value: element.id,
          });
        });
      }
      //TEMPLATES
      const url2 = `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}`;
      const resp2 = await TemplateService.getTemplates(url2, this.$store);
      if (resp2) {
        resp2.rows.forEach((element) => {
          this.templateOptions.push({
            label: element.name,
            value: element.id,
          });
        });
      }
    },
    async onSubmit(data) {
      const isValid = await this.$refs.simpleRules.validate();
      if (isValid) {
        this.isDisabled = true;
        console.log("this.contractData onsubmit", this.contractData);
        //armar la estructura
        var contractData = {
          name: this.contractData.name,
          development_by: this.contractData.development_by,
          responsible_sst: this.contractData.responsible_sst,
          template_id: this.contractData.template_id,
          scope: this.contractData.scope,
          company_id: this.contractData.company_id,
          evaluation_date: this.contractData.evaluation_date,
          expiration_date: this.contractData.expiration_date,
          contract_user: this.contractData.contract_user,
          qty_workers: this.contractData.qty_workers,
          contract_detail: [],
        };
        for (let i = 0; i < this.contractData.contract_detail.length; i++) {
          var children = [];
          for (
            let j = 0;
            j < this.contractData.contract_detail[i].children.length;
            j++
          ) {
            const elementChild = {
              number: this.contractData.contract_detail[i].children[j].number,
              description:
                this.contractData.contract_detail[i].children[j].description,
              ref_legal:
                this.contractData.contract_detail[i].children[j].ref_legal,
              finding: this.contractData.contract_detail[i].children[j].finding,
              status: "pending",
              compliance: "",
              compliance_percentage: 0,
            };
            children.push(elementChild);
          }
          const element = {
            number: this.contractData.contract_detail[i].number,
            description: this.contractData.contract_detail[i].description,
            ref_legal: this.contractData.contract_detail[i].ref_legal,
            finding: this.contractData.contract_detail[i].finding,
            status: "pending",
            compliance: "",
            compliance_percentage: 0,
            children: {
              create: children,
            },
          };
          contractData.contract_detail.push(element);
        }
        console.log("contractData", contractData);
        this.addContract(contractData);
      }
    },
    async addContract(data) {
      const resp = await ContractService.saveContract(data, this.$store);
      if (resp.success) {
        this.$swal({
          title: "Añadido",
          text: "El contrato ha sido registrado",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        this.$router.push("/contract/list");
      } else {
        this.$swal({
          title: "Error!",
          text: " Hubo un error al guardar el contrato",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
    async showTemplate(id) {
      // this.contractData.contract_detail = resp.data.template_detail;
      //realizar el formato
      var arrayFormatTable = [];
      for (let i = 0; i < this.contractData.contract_detail.length; i++) {
        for (
          let j = 0;
          j < this.contractData.contract_detail[i].children.length;
          j++
        ) {
          var element = {
            numberParent: this.contractData.contract_detail[i].number,
            title:
              j == 0 ? this.contractData.contract_detail[i].description : null,
            qtyChildren:
              j == 0
                ? this.contractData.contract_detail[i].children.length
                : null,
            idParent: this.contractData.contract_detail[i].id,
            number: this.contractData.contract_detail[i].children[j].number,
            subtitle:
              this.contractData.contract_detail[i].children[j].description,
            ref_legal:
              this.contractData.contract_detail[i].children[j].ref_legal,
          };
          console.log("element", element);
          arrayFormatTable.push(element);
        }
      }
      this.templateFormat = arrayFormatTable;
      console.log("arrayFormatTable", arrayFormatTable);
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
  },
};
</script>

<style scoped>
.modal_button {
  display: flex;
  justify-content: end;
}
.modal_title {
  display: flex;
  align-items: center;
}
.b-form-btn-label-control.form-control > label {
  height: auto !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
