/* eslint-disable*/
import VueStoreService from './VueStoreService';
const CompanyService = {
    async getCompanys(filter,store){
        var resp = await VueStoreService.get("api/company"+filter, store)
        return resp;
    },
    async saveCompany(data,store){
        var resp = await VueStoreService.post("api/company",data,store)
        return resp;
    },
    async getCompany(company_id,store){
        var resp = await VueStoreService.get( "api/company/"+company_id,store)
        return resp;
    },
    async updateCompany(company_id,data,store){
        var resp = await VueStoreService.put("api/company/"+company_id,data,store)
        return resp;
    },

    async deleteCompany(company_id,store){
        var resp = await VueStoreService.delete("api/company/"+company_id,store)
        return resp;
    },
}

export default CompanyService;